@import '../../scss/global/global';

.Notification {
  &__text {
    @include inter-narrator;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    // position: absolute;
    // margin: auto;
    color: $dark2;
    padding: 0px 50px;
     
  }
  &__choicesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    margin: auto; 
    bottom: 0;
    width: 100%;
    &__choices {
      padding: 5px;
      display: flex;
    }
  }
}
