@import '../../scss/global/global';

.Narrator {
  &__text {
    @include inter-narrator;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    // position: absolute;
    // margin: auto;
    color: $white;
    padding: 0px 50px;
    background: radial-gradient(
      81.88% 46.79% at 50% 50%,
      rgba(0, 10, 17, 0.56) 33.85%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
  &__choicesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    margin: auto; 
    bottom: 0;
    width: 100%;
    &__choices {
      padding: 5px;
      display: flex;
    }
  }
}
