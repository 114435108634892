@import './spacing';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

$inter: 'Inter', sans-serif;
$staatliches: 'Staatliches', cursive;

/* Take note */
// spaces in figma replace with "-"
// $inter is the inter font, can adjust font weight accordingly

// inter fonts
@mixin h1-new {
    font-family: $inter; 
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 90%;
    letter-spacing: -0.02em;
}

@mixin inter-charchoicename {
    font-family: $inter;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 68px;
    letter-spacing: -2px;
    text-align: left;
}

@mixin inter-name {
    font-family: $inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}

@mixin inter-700 {
    font-family: $inter;
    font-weight: 700;
}

@mixin inter-whatsappName {
    font-family: $inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

@mixin inter-chaptChoiceName {
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

@mixin inter-narrator {
    font-family: $inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
}

@mixin inter-whatsapp {
    font-family: $inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}

@mixin inter-chaptchoice600 {
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;

}

@mixin inter-chaptchoice500 {
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

}

@mixin inter-charchoicescroll {
    font-family: $inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
}

@mixin p2-normal-body {
    font-family: $inter;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
}

// staatliches fonts
@mixin h1 {
    font-family: $staatliches;
    font-style: normal;
    font-weight: normal;
    font-size: 72px;
    line-height: 90%;
    letter-spacing: 0.02em;
}

@mixin h4 {
    font-family: $staatliches;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 27px;
	/* identical to box height */
	letter-spacing: 0.12em;
	text-transform: uppercase;
}

