.top-border{
    background: #C4C4C4;
    
}

.time{
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left:20px;
}

.misc-icons{
    margin-left: 210px;
}

.email-wrapper {
    margin-top: 10px;
    padding: 10px; 
    max-height: 655px;
    overflow-y: scroll;  
    }

.email-header{
    height: 40px;
    background: #C4C4C4;
    font-size: 20px; 
    font-style: bold;
    vertical-align: middle;

    .arrow-back{
        margin-top: 9px;
        margin-left: 5px;
    }

    .email-compose{
        margin-top: 7px;
        margin-left: 5px;
    }

    .close-icon{
        margin-top: 7px;
        margin-left: 180px;
    }
}

.email-body {
    overflow-y: scroll;
    height: 300px; 
    font-size: 18px;
    transition: height 1s; 
    margin-top: 10px;
    &.full {
        height: 475px; 
        transition: height 1s;
    }
}

.email-from{
    margin-top: 9px;
    height: 40px;
    font-size: 18px; 
}

.arrow-drop{
    margin-left: 100px;
}
.email-to{
    margin-top: 9px;
    height: 40px;
    font-size: 18px; 
}

.email-subject{
    margin-top: 9px;
    height: 40px;
    font-size: 18px; 
}

.send-button{
    margin-left: 20px;
    font-size: 20px;
}

.attachment-delete{
    margin-left: 140px;
}

@import "../../scss/global/global"; 

.typed-container {
    display: inline-block;
}
.typed-out {
    // margin-top: 12px !important; 
    font-family: "Inter";
    &:last-child {
        &:after{
            content: "|";
            animation: blink 500ms linear infinite alternate;
        }
    }
}

.type {
    font-family: "Inter";
    margin-bottom: 16px; 
    

    &:last-child {
        color:#0000;
        background:
        linear-gradient(-90deg,#000000 2px,#0000 0) 10px 0,
        linear-gradient(#000000 0 0) 0 0;
        background-size:calc(var(--n)*1ch) 200%;
        -webkit-background-clip:padding-box,text;
        background-clip:padding-box,text;
        background-repeat:no-repeat;
        animation: 
        b .5s infinite steps(1),   
        t calc(var(--n)*.03s) steps(var(--n)) forwards;
    }
    br {
        display: block;
        margin-bottom: 10px;
        line-height:12px;
        content: " ";
    }
  }
  
  @keyframes t{
    from {background-size:0 200%}
  }
  @keyframes b{
    50% {background-position:0 -100%,0 0}
  }

@-webkit-keyframes blink{
	0%{opacity: 0;}
	100%{opacity: 1;}
}

@-moz-keyframes blink{
	0%{opacity: 0;}
	100%{opacity: 1;}
}

@keyframes blink{
	0%{opacity: 0;}
	100%{opacity: 1;}
}   

.text-blue {
    color: #0070C0;
}

.Email {
    &__inbox {
        &__header {
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); 
            background: $tangerine100;
            height: 75px; 
            padding: 14px 20px; 
            align-items: center;
            display: flex;
        }
        &__nextbutton {
            position: absolute; 
            top: 75px; 
            height: 100px !important; 
        }
    }
    &__header {
        height: 75px;
        background-color: $tangerine100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 20px; 

        &--right {
            margin-right: 50px; 
            align-items: center;
            display: flex;
        }

    }
    &__details {
        background-color: $white;
        border-style: none none solid none;
        padding: 11px 16px; 
        border-width: 1px; 
        border-color: rgba(0, 0, 0, 0.1);
        display: flex;
        &--name {
            color: #747A98;   
            margin-right: 8px;
        }
    }
    &__messages {
        background-color: $white;
        padding: 9px 16px; 
        scroll-snap-type: y mandatory;   

        overflow-y: scroll;
        user-select: none;
        // height: 100vh; 
        height: calc(calc(var(--vh, 1vh) * 100) - 148px); 
        @media screen and (min-device-width:600px) {
            height: 395px; 
        }
        > div {
            scroll-snap-align: end;
        }

        &.choices {
            transition: height 0.4s; 
            height: calc(calc(var(--vh, 1vh) * 100) - 442px); 
        // height: 55.3vh; 
            @media screen and (min-device-width:600px) {
                height: 221px; 
            }
        }
        &--sender, &--receiver {
            &.newSpeaker {
                margin-top: 20px; 
            }
        }
    }
    &__replySection {
        margin-top: 22px; 
        &--content {
            margin-left: 24px; 
            border-left: 1px solid #0000003a;
            padding-left: 12px;
        }
        
    }
    &__sendWrapper {
        border-style:solid none none none;
        border-width: 1px; 
        border-color: rgba(0, 0, 0, 0.1);
        background-color: $white;
        padding: 10px 16px; 
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px; 
        &__sendButton {
            border-radius: 48px;
            padding: 12px 16px; 
            width: 85%; 
            display: flex;
            align-items: center;
            height: 44px; 
            color: $midnight100;
            font-size: 14px;
            // opacity: 80%;
            background-color: $midnight10;
            &.have-choice {
                font-weight: 600;
            }
            &.choice {
                border: $aqua100 solid 2px;
                background-color: transparent;
                color: white; 
            }
            &--right {
                background-color: #FF3715;

                border-radius: 30px;
                height: 40px;
                width: 40px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }

    }
    &__choicesWrapper {
        height: 170px; 
        overflow-y: scroll;
        transition: height 0.4s; 
        background-color: $white;

        animation:opac 0.9s;
        @keyframes opac{
            from{opacity:0} 
            to{opacity:1}
        }
        .choices {
            border-radius: 12px;
            margin: 10px; 
            user-select: none;
            padding: 13px 20px;
            text-align: center;
            background-color: $tangerine10;
            color: $tangerineBase;
            cursor: pointer;
            &:hover {
                background-color: $tangerineBase;
                color: $white;
            }
    
        }
    }
}

.InboxItem {
    padding: 12px 12px; 
    background: white;
    display: flex;
    align-items: center;
    &__nonStory { 
        height: 12px; 
        background-color: $lapis20;
        border-radius: 10px;
        margin: 4.5px 0; 
    }
    &--image {
        border-radius: 50px;
        height: 48px; 
        width: 48px; 
        margin-right: 14px; 
    }
    &--from {
        font-weight: 700 !important;
        color: $midnight100;
        &--nonStoryline {
            color: rgb(171, 171, 171);
        }
    }
    &--subject {
        font-weight: 700 !important;
        color: $midnight100;
    }
    &--preview {
        color: $midnight60; 
        &--nonStoryline {
            color: rgb(171, 171, 171);
        }
    }
    
}


// .Email-box {
//     width: 100%; 
//     overflow: hidden;
//     padding-right: 20px;
//     img {
//         border-radius: 50%; 
//         width: 54px; 
//         height: 54px; 
//     }
// }

// .text-area > div {
//     scroll-snap-align: end;
    
// }

// .text-area {
//     // background-color: #808080;
//     overflow-y: scroll;
//     transition: height 0.4s; 
//     height: 66.1vh; 
//     scroll-snap-type: y mandatory;


//     @media screen and (min-device-width:481px) {
//         height: 360px; 
//     }

//     &.full {
//         transition: height 0.4s; 
//         height: 66.1vh; 
//         @media screen and (min-device-width:481px) {
//             height: 360px; 
//         }
//     }
// }
// .choices-wrapper {
//     height: 170px; 
//     overflow-y: scroll;
//     transition: height 0.4s; 
//     background-color: #414141;

//     animation:opac 0.9s;
    

//     // display: none;
//     // z-index: -1;
//     &.no-choices {
//         // display: none;
//         height: 0;
//         transition: height 0.5s; 
//     }
    
//     // .choices-title {
//     //     text-align: center;
//     //     font-weight: 800;
//     // }
//     .choices {
//         border-radius: 12px;
//         margin: 10px; 
//         padding: 10px;
//         text-align: center;
//         background-color: rgba(29, 191, 123, 0.4);
//         color: white;
//         cursor: pointer;
//         &:hover {
//             background-color: rgb(91, 158, 102);
//         }

//     }
// }
// @keyframes opac{
//     from{opacity:0} 
//     to{opacity:1}
// }
// .send-wrapper {
//     padding: 10px 10px; 
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     color: grey; 
//     border-top: solid grey 0.1px;
//     height: 60px; 
//     background-color: #333333;

//     // position: absolute;
//     // bottom: 20px; 
//     right: 0; 
//     left: 0;
//     &__send-button {
//         border-radius: 20px;
//         width: 280px; 
//         color: rgb(184, 184, 184);
//         display: flex;
//         align-items: center;
//         padding-left: 10px; 
//         height: 40px; 
//         background-color: #666666;
//         &.choice {
//             border: #1DBF7B solid 2px;
//             background-color: transparent;
//             color: white; 
//         }
//     }
//     .send-button-right {
//         background-color: #1DBF7B;
//         border-radius: 30px;
//         height: 40px;
//         width: 40px;
//         text-align: center;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: white;
//     }
//     .wa-sticker {
//         width: 6%;
//         height: 6%;
//     }
// }