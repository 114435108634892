@import "../../scss/global/global"; 

// html, body {
//     height: 100%; 
// }
.Twitch {
    &__toolbar {
        height: 64px;
        left: 0px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    }
    &__video {
        height: 184px;
        // object-fit: cover;
        overflow: hidden;
        // object-position: 0px -20px;
        &--image{
            object-fit: cover;
            width:100%;
            height: 184px;
            // object-position:0px -200px;
        }
    }

    &__gradient{
        // position: absolute;
        width: 358px;
        height: 184px;
        margin-top: -184px;
        z-index: 2;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 43.93%);
        mix-blend-mode: normal;
    }
    
    &__header {
        height: 40px;
        left: 0px;
        padding: 8px 12px;
        background-color: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        display: flex;
    
        // height: 64px;
        // background-color: $midnight100;
        // display: flex;
        // padding: 14px 20px; 
        // &--profile {
        //     height: 36px; 
        //     width: 36px; 
        //     border-radius: 50%;
        //     margin-right: 12px;
        // }
        &__description {
            color: $midnight100;
            &--name {
                // @include inter-whatsappName; 
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                text-transform: uppercase;
            }
            &--status {
                @include p2-normal-body; 
                font-size: 12px !important;
            }
        }
    }
    &__messages {
        scroll-snap-type: y mandatory;
        overflow-y: scroll;
        user-select: none;
        background: $white;
        // height: 100vh; 
        height: calc(100vh - 292px);
        @media screen and (min-device-width:600px) {
            height: 371px; 
        }
        > div {
            scroll-snap-align: end;
        }
        // &--sender {
        //     background: #C9F3F9;
        //     width: 304px; 
        //     padding: 12px;
        //     border-radius: 12px 3px 3px 12px;
        //     margin-bottom: 4px;
        //     &.newSpeaker { 
        //         border-radius: 12px 12px 3px 12px;
        //     }
        // }
        &--sender {
            // background: $white;
            color: $midnight100;
            // width: 100%;
            padding: 10px 8px 8px; //edited left-right padding values from 12 to 8px
            // font-family: Inter;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 14px;
            // line-height: 150%;
            display: flex;
            // align-items: center;

            // margin-bottom: 4px;
            // margin-left: 16px;
            // border-radius: 3px 12px 12px 3px;
            // &.newSpeaker { 
            //     border-radius: 12px 12px 12px 3px;
            // }
            
            &--name {
                // @include inter-whatsapp; 
                // text-transform: capitalize;
                // margin-bottom: 4px;

                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                padding-left: 4px;

                color: #664EFC;
            }

            &--profile {
                width: 20px;
                height: 20px;
            }

            &--message {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
            }
        }
        &--receiver {
            // background: $white;
            color: $midnight100;
            // width: 100%;
            padding: 10px 8px 8px; //edited left-right padding values from 12 to 8px
            // font-family: Inter;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 14px;
            // line-height: 150%;
            display: flex;
            // align-items: center;

            // margin-bottom: 4px;
            // margin-left: 16px;
            // border-radius: 3px 12px 12px 3px;
            // &.newSpeaker { 
            //     border-radius: 12px 12px 12px 3px;
            // }
            
            &--name {
                // @include inter-whatsapp; 
                // text-transform: capitalize;
                // margin-bottom: 4px;

                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                padding-left: 4px;

                // color: #664EFC;
            }

            &--profile {
                width: 20px;
                height: 20px;
            }

            &--message {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
            }
        }
        &.choices {
            transition: height 0.4s; 
            height: calc(100vh - 470px); // edited from 313px to 547px
        // height: 55.3vh; 
            @media screen and (min-device-width:600px) {
                height: 190px;  // change from 350 to 175px
            }
        }
        &--sender, &--receiver {
            &.newSpeaker {
                margin-top: 20px; 
            }
        }
    }
    &__sendWrapper {

        background-color: white;
        padding: 10px 16px; 
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px; 
        // position:absolute;
        bottom:0px;
        width:100%;
        // margin-top: 20px;
        &__sendButton {
            border-radius: 48px;
            padding: 12px 16px; 
            width: 85%; 
            display: flex;
            align-items: center;
            height: 44px; 
            color: $midnight100;
            // may remove
            // font-family: Inter;
            // font-style: normal;
            // font-weight: normal;
            // line-height: 140%;
            font-size: 14px;
            background-color: $midnight10;
            &--right {
                background-color: $lapisBase;
                border-radius: 30px;
                height: 40px;
                width: 40px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
            &.choice {
                // border: $aqua100 solid 2px;
                // background-color: transparent;
                // color: white; 
                color: #172153;
                font-weight: 600;
            }
            &--purpleright {
                background-color: #664EFC; // lapis base not a colour yet
                border-radius: 30px;
                height: 40px;
                width: 40px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }

    }
    &__choicesWrapper {
        height: 182px; 
        overflow-y: scroll;
        transition: height 0.4s; 
        background-color: white;
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;


        animation:opac 0.9s;
        @keyframes opac{
            from{opacity:0} 
            to{opacity:1}
        }
        .choices {
            border-radius: 12px;
            margin: 5px; 
            user-select: none;
            padding: 10px 20px;
            text-align: center;
            background: #EFF0FF;
            border: 1px solid rgba(186, 190, 254, 0.5);
            box-sizing: border-box;
            border-radius: 8px;
            // background-color: $aqua1;
            color: #664EFC;
            font-weight: 500;
            cursor: pointer;
            &:hover {
                background: #664EFC;
                color:white;
            }
    
        }
    }
    &__innerMono {
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 200px;
        padding: 16px;
        z-index: 1;
        background: linear-gradient(180deg, #4f4dbe 44.7%, #664efc 100%);
        &--text {
         
            color: white;
            font-style: italic;
        }
    }
}

// .whatsapp-box {
//     width: 100%; 
//     overflow: hidden;
//     padding-right: 20px;
//     img {
//         border-radius: 50%; 
//         width: 54px; 
//         height: 54px; 
//     }
// }

// .text-area > div {
//     scroll-snap-align: end;
    
// }

// .text-area {
//     // background-color: #808080;
//     overflow-y: scroll;
//     transition: height 0.4s; 
//     height: 66.1vh; 
//     scroll-snap-type: y mandatory;


//     @media screen and (min-device-width:481px) {
//         height: 360px; 
//     }

//     &.full {
//         transition: height 0.4s; 
//         height: 66.1vh; 
//         @media screen and (min-device-width:481px) {
//             height: 360px; 
//         }
//     }
// }
// .choices-wrapper {
//     height: 170px; 
//     overflow-y: scroll;
//     transition: height 0.4s; 
//     background-color: #414141;

//     animation:opac 0.9s;
    

//     // display: none;
//     // z-index: -1;
//     &.no-choices {
//         // display: none;
//         height: 0;
//         transition: height 0.5s; 
//     }
    
//     // .choices-title {
//     //     text-align: center;
//     //     font-weight: 800;
//     // }
//     .choices {
//         border-radius: 12px;
//         margin: 10px; 
//         padding: 10px;
//         text-align: center;
//         background-color: rgba(29, 191, 123, 0.4);
//         color: white;
//         cursor: pointer;
//         &:hover {
//             background-color: rgb(91, 158, 102);
//         }

//     }
// }
// @keyframes opac{
//     from{opacity:0} 
//     to{opacity:1}
// }
// .send-wrapper {
//     padding: 10px 10px; 
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     color: grey; 
//     border-top: solid grey 0.1px;
//     height: 60px; 
//     background-color: #333333;

//     // position: absolute;
//     // bottom: 20px; 
//     right: 0; 
//     left: 0;
//     &__send-button {
//         border-radius: 20px;
//         width: 280px; 
//         color: rgb(184, 184, 184);
//         display: flex;
//         align-items: center;
//         padding-left: 10px; 
//         height: 40px; 
//         background-color: #666666;
//         &.choice {
//             border: #1DBF7B solid 2px;
//             background-color: transparent;
//             color: white; 
//         }
//     }
//     .send-button-right {
//         background-color: #1DBF7B;
//         border-radius: 30px;
//         height: 40px;
//         width: 40px;
//         text-align: center;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: white;
//     }
//     .wa-sticker {
//         width: 6%;
//         height: 6%;
//     }
// }