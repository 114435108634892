@import "../../../scss/global/global";

.SideMenu {
  // margin: auto;
  z-index: 1500; 
  overflow: hidden !important;
  width: 310px !important; 
  &__background {
    img {
      width: 310px; 
    }
  }
  &__top {
    overflow: hidden;

    z-index: 1200 !important;
    padding: 41px 23px 25px 27px;
    position: absolute;
    top: 0; 
    left: 0; 
    // height: 568px;
    width: 310px; 
    // background-color: black !important;
    .SideMenu {
      &__current {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 56px;
        color: $aqua40;
      }
      &__gameDetails {
        font-size: 48px;
        font-weight: 900;
        text-transform: capitalize;
        margin-bottom: 14px;
        color: $aqua20;
      }
      &__playSection {
        display: flex;
        justify-content: space-between;
        margin-bottom: 61px;
        &--timeLeft {
          font-size: 14px;
          font-weight: 700;
          color: $aqua80;
        }
        &--playButton {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          height: 50px; 
          width: 50px;
        }
      }
    }
  }

  &__bottom {
    overflow: hidden;
    padding-top: 27px;
    .SideMenu {
      &__menuitems {
        &__item {
          margin-bottom: 0px;
        }
      }
    }
  }
  &__menuitems {
    &__item {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }
    &__icons {
      margin-right: 23px;
      width: 20px;
      vertical-align: bottom;
    }
    &__label {
      font-weight: 700;
      font-size: 15px;
      text-decoration: none;
      color: $white;
      &:hover {
        cursor: pointer;
      }
    }
  }
  hr {
    width: 310px; 
    color: #172153;
    margin-left: -27px;
    border: 1px solid;
  }
}

.MenuWrapper {
  &__menuButton {
    position: absolute;
    cursor: pointer; 
    top: 16px; 
    right: 16px; 
    color: rgba(243, 243, 243, 0.83);
    height: 48px;
    width: 48px;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    &:active, &:hover {
      .MenuWrapper {
        &__menuButton {
          #menu-button { 
            #circle {
              fill: grey !important; 
            }
          }
        }
      }
    }
    &:focus {
      background-color: red;
      box-shadow: 3px 3px 5px lightgrey;
    }
  }
}

.MuiDrawer-paper {
  overflow-y: hidden !important;
}
