@import "../../scss/global/global"; 

.HelpPage {
    &__nav {
        padding: 10px; 
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .MenuWrapper__menuButton {
            position: absolute;
            // width: 24px; 
            top: 11px;
            right: 20px;
        }
        &--name {
            margin-left: 18px; 
            @include inter-chaptChoiceName; 
            color: #072435;
            text-decoration: none;
        }
    }
    &__top {
        @include inter-700; 
        background-image: url("./assets/background.png");
        height: 406px;
        background-size: cover;                   
        background-repeat: no-repeat;
        background-position: center center;   
        padding: 42px 28px;
        color: $white; 
        font-size: 24px;
        text-align: center;
        a {
            text-decoration: none;
        }
        &--icon {
            background-color: $lapis10;
            height: 56px !important;
            width: 56px !important;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;
        }
    }
}
.MuiAccordion-root-193 {
    &:before {
        background-color: #D8F4F6 !important;
    }
}