.reflectionsContainer {
    &::-webkit-scrollbar {
    display: none;
  }

  &__reflectionBubbles {
      opacity: unset !important;
  }
}

