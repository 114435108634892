@import "../../scss/global/global";

.CharacterChoices {
  font-family: 'Inter', Roboto;
  &__header {
    height: 66px; 
    // width: 100%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    // justify-content: space-between;
    // background-color: darkgrey;
    .MenuWrapper__menuButton {
      position: absolute;
      // width: 24px; 
      top: 11px;
      right: 20px;
    }
    &--placeholder {
      width: 44px; 
    }
  }
  &__banner {
    width: 100%;
  }
  &__scrollMenu {
    display: flex;
    padding: 14px 0px 32px 22.5px;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    &__character {
      @include inter-charchoicescroll;
      margin-right: 14.5px;
      width: 69px;
      .active {
        margin-top: 12px;
      }
      .disable {
        margin-top: 12px;
        color: $midnight60;
      }
    }
  }
  &__card {
    &__feed {
      display: flex;
      align-items: center;
      padding: 20px 0px 14px 16px;
      &--profilePic {
        height: 40px;
        width: 40px;
        margin-right: 16px;
        border-radius: 50px;
      }
      .profile_Name {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 4px;
      }
      .profile_status {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #3835c1;
        opacity: 0.6;
      }
    }

    &--imagePost {
      width: 100%;
    }
    &--profileButtons {
      padding: 15px 18px;
      display: flex;
      justify-content: space-between;
    }
    &--description {
      padding: 0px 16px;
      .likes {
        font-weight: 700;
      }
    }
  }

  &__discover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    .profile_tile {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #26248f;
    }
    a {
      text-decoration: none;
      .play_button {
        border: none;
        background-color: white;
        background: #664efc;
        border-radius: 24px;
        width: 104px;
        height: 40px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__bottomDivider {
    height: 8px;
    width: 100%;
    background-color: $lapis10;
  }
}

// hr{
//     background-color: #F0F1FC;
//     border-top: 8px;
//   }

//   article{
//     padding: 0px 0px 20px 0px;
//   }

//   .profile_buttons_right{
//     float: right;
//     padding: 0 10px 0 0;
//   }

//   .profile_tile{
//     padding: 5px 0px 0px 10px;
//     font-family: Inter;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 17px;
//     color: #26248F;
//   }

//   .profile_tile_right:after{
//     float: right;
//     padding:0px 0px 0px 0px;
//   }

//   #image_post{
//     width: 100%;
//     height: auto;
//   }

//   /* Profile - styling */
//   ul{
//     list-style: none;
//   }

//   li{
//     display: inline-block;
//     margin: 0 20px 0 -20px;
//   }

//   /* Profile - scrollable */
//   div.scrollmenu{
//     white-space: nowrap;
//     overflow: auto;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 11px;
//     line-height: 13px;
//     text-align: center;
//   }

//   button{
//     border: none;
//     background-color: white;
//   }

.play_button {
  border: none;
  background-color: white;
  background: #664efc;
  border-radius: 24px;
  width: 104px;
  height: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: white;
}

//   /* Character Card */
//   /* Create two equal columns that floats next to each other */
//   .characterCard{
//     background-color: #fff;
//   }

//   .profile_buttons {
//     background-color: #fff;
//     overflow: hidden;
//     position: relative;
//     padding: 10px 0px 0px 10px;
//   }

//   .profile_buttons #navRight {
//     float: right;
//   }

//   .column {
//     float: left;
//     padding: 10px 0px 0px 20px;
//   }

//   .likes{
//     padding: 5px 0px 0px 10px;
//     font-weight: 700;
//   }

//   .post_caption{
//     padding: 0px 0px 0px 10px;
//   }

//   .vertical-center{
//     margin: 0px;
//     top: 50%;
//   }

//   .card_profile_pic{
//    padding-top: 5px;
//   }
