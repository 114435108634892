@import "../../scss/global/global";
.ScenePage {
  user-select: none;
  font-family: 'Inter', Roboto;
  &__speaker {
    &--left,
    &--right {
      // height: 200px !important;
      // width: 40%;
      width: 240px !important;
    }
    &--left {
      position: absolute !important;
      left: 0;
      bottom: 100px;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
      &.nonSpeaker {
        filter: brightness(50%);
        transition: filter 0.5s ;
      }
    }
    &--right {
      position: absolute !important;
      right: 0;
      bottom: 100px;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
      &.nonSpeaker {
        filter: brightness(50%);
        transition: filter 0.5s ;
      }
    }
  }
  &__nameWrapper {
    position: relative;
    top: -20px;
    width: 100%;
  }

  &__name {
    @include inter-name;
    color: $white;
    height: 35px;
    padding: 4px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    text-transform: capitalize;
    position: relative;
    bottom: -20px;
    margin: 0px 20px;
    white-space: nowrap;
  }

  &__story {
    position: absolute;
    bottom: 0;
    width: 100%;
    &__innerMono {
      height: 48px;
      background: linear-gradient(
        180deg,
        rgba(79, 77, 190, 0) 13.02%,
        #4f4dbe 100%
      );
    }
    &__text {
      min-height: 200px;
      font-size: 1rem;
      &.default {
        background: $white;
        box-shadow: 0px -8px 24px 0px #0000001f;
      }
      &.innerMonologue {
        background: linear-gradient(180deg, #4f4dbe 44.7%, #664efc 100%);
        color: white;
        font-style: italic;
      }
      &--line {
        padding: 32px 24px 28px 24px;
      }
    }
  }
  &__choicesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // position: absolute;
    // bottom: 0;
    &__choices {
      padding: 5px;
      display: flex;
    }
  }
}
