@import "../../scss/global/global";
.ShareableImage {
    
    position: absolute;
    width: 300px;
    height: 300px;
    left: calc(50% - 300px/2 - 0.5px); // centered
    // top: 173px;
    background: #6C70DD;
    overflow: hidden;
    border: 1px solid #FFFFFF; 
    border-radius: 5px;

    &__gradient {
        position: absolute;
        width: 300px;
        height: 300px;
        
        background: linear-gradient(179.95deg, rgba(23, 33, 83, 0) 0.04%, #172153 99.96%);
        mix-blend-mode: multiply;
        opacity: 0.3;
        transform: rotate(-180deg);
    }

    &__bottomGradient {
        position: absolute;
        width: 300px;
        height: 189px;
        left: 0px;
        top: 111px;

        background: linear-gradient(360deg, rgba(56, 53, 193, 0.71) 4.74%, rgba(56, 53, 193, 0) 93.39%);
        mix-blend-mode: normal;
    }

    &__reflectionContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;

        position: absolute;
        width: 252px;
        left: 24px;
        top: 24px;
    }

    &__overline {
        position: static;
        // width: 150%;
        height: 15.5px;
        left: 0px;
        bottom: 162.5px;

        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 12px; 
        line-height: 130%;
        word-break: break-all;

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0 0 15.5px 0px;

        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #6C70DD;
    }

    &__body {
        position: static;
        height: 100%;
        left: 0%;
        right: 0%;
        bottom: 0px;

        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 110%;
        text-align: left;

        flex: none;
        order: 1;
        flex-grow: 0;

        color: #000000;
    }

    &__bodyBig {
        position: static;
        height: 100%;
        left: 0%;
        right: 0%;
        bottom: 0px;

        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        text-align: left;

        flex: none;
        order: 1;
        flex-grow: 0;

        color: #000000;
    }

    &__logo {
        position: absolute;
        width: 62px;
        height: 50px;
        left: 24px;
        top: 22px;

        filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.04));
        transform: rotate(-2deg);
    }

    &__avatar {
        position: absolute;
        width: 300px;
        height: 300px;
        left: 0px;
        top: 0px;


        background: white;
        object-fit: cover;
        mix-blend-mode: normal;
        opacity: 0.6;
    }



}
// implemented in ShareableImageContainer
.ShareableImageBorder {
    position: absolute;
    width: 300px;
    height: 300px;
    left: calc(50% - 300px/2 - 0.5px); // centered
    top: 173px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    background-color: transparent;
}

// .downloadBtn {    
//     font-size: 10px;
//     // height: 40px; 
//     width: 180px;
//     // display: flex;
//     // justify-content: center;
//     // align-items: center;
//     text-transform: uppercase;
//     border-radius: 50px !important;
//     border-color: $lapisDark2 !important;
//     color: $white;
//     cursor: pointer;
//     left: calc(50% - 180px/2 - 0.5px) !important; // centered
//     position: relative;
//     top: 315px;

// }