.story {
    .reset-button {
        display: none;
    }
    .save-button {
        bottom: 0; 
        left: 0;
        height: 20px;
        position: absolute;
    }
    
}



.ink-controller {
    .name {
        position: absolute;
        top: 0;
    }
}

.ui-screens {
    min-height: 90%; 
}
.MuiContainer-root {
    padding: 0% !important;
}