@import '../../../../src/scss/global/global';

.MultipleChoice {
  &__text {
    @include inter-narrator;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: absolute;
    // margin: auto;
    color: $white;
    padding: 0px 50px;
    background: radial-gradient(
      81.88% 46.79% at 50% 50%,
      rgba(0, 10, 17, 0.85) 33.85%,
      rgba(0, 0, 0, 0.7) 100%
    );    
  }
}
